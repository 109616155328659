import React from 'react';
import Layout from 'src/layouts/Page';
import Seo from 'src/components/Seo';
import { theme } from 'src/theme';
import ApiWrapper from 'src/components/ApiWrapper';
import useResizeObserver from 'use-resize-observer';
import { navbarHeight } from 'src/components/Navbar/utils';
import SectionContainer from 'src/sections/shared/SectionContainer';
import Typography from 'src/components/core/Typography';
import Button from 'src/components/core/Button';

const NotFoundPage: React.FC = () => {
  const {
    height: footerHeight = 387,
    ref: footerRef,
  } = useResizeObserver<HTMLDivElement>();
  return (
    <ApiWrapper apiServices={['home-api']}>
      <Layout
        navbarSpacer
        navbarProps={{
          backgroundCss: theme.palette.secondary.LIGHT_BLUE,
          darkText: true,
        }}
        footerRef={footerRef}
      >
        <Seo title="Page Not Found" />
        <SectionContainer
          background="light-blue"
          style={{
            minHeight: `calc(100vh - ${footerHeight}px - ${navbarHeight})`,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography variant="h2" color="blue" centered className="mb-6" style={{ fontWeight: 700 }}>
            404: Page not found
          </Typography>
          <Typography variant="h3" color="blue" centered className="mb-2">
            Oh no! We can&apos;t find the page you&apos;re looking for.
          </Typography>
          <Typography variant="p2" color="blue" centered>
            Check the web address and try again or return to the Bizwise homepage.
          </Typography>
          <Button href="/" variant="filled" color="teal" className="mt-6">
            Back to the home page
          </Button>
        </SectionContainer>
      </Layout>
    </ApiWrapper>
  );
};

export default NotFoundPage;
